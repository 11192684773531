import React from "react";
import { motion } from "framer-motion";

const draw = {
  hidden: { width: 0 },
  visible: (i) => {
    // const delay = 5 + i * 0.5;
    return {
      width: i
      // transition: {
      //   height: { delay, type: "just", duration: 2, bounce: 0 }
      // }
    };
  }
};

const text = {
  hidden: {
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)"
  },
  visible: {
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)"
  }
};

export const AnimatedLogo1 = () => {
  return (
    <motion.svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 215.22999 51.280004"
      width="215.23"
      height="51.280003"
      pagecolor="#ffffff"
      initial="hidden"
      animate="visible"
      bordercolor="#000000"
      borderopacity="0.25"
      showgrid="false"
    >
      <motion.g
        class="bar"
        id="g128"
        transform="translate(-30.469999,-30.709999)"
      >
        <motion.rect
          x="74.029999"
          y="30.709999"
          transition={{
            width: { duration: 2, ease: "easeInOut", delay: 1 }
          }}
          variants={draw}
          custom={1.36}
          initial="hidden"
          width={0}
          animate="visible"
          height="29.040001"
          id="rect4"
        />
        <motion.rect
          // variants={draw}
          x="76.480003"
          y="30.709999"
          transition={{
            width: { duration: 2, ease: "easeInOut", delay: 1 }
          }}
          variants={draw}
          custom={3.5699999}
          initial="hidden"
          width={0}
          animate="visible"
          height="29.040001"
          id="rect6"
        />
        <motion.rect
          x="81.190002"
          y="30.709999"
          // variants={draw}
          transition={{
            width: { duration: 2, ease: "easeInOut", delay: 1 }
          }}
          variants={draw}
          custom={2.8499999} 
          initial="hidden"
          width={0}
          animate="visible"
          height="29.040001"
          id="rect8"
        />
        <motion.rect
          // variants={draw}
          x="85.010002"
          y="30.709999"
          className="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={7.77} 
          initial="hidden"
          width={0}
          animate="visible"
          height="29.040001"
          id="rect10"
        />
        <motion.rect
          x="93.93"
          // variants={draw}
          y="30.709999"
          className="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={1.85} 
          initial="hidden"
          width={0}
          animate="visible"
          height="29.040001"
          id="rect12"
        />
        <motion.rect
          x="96.989998"
          y="30.709999"
          className="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={7.6300001}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect14"
        />
        <motion.rect
          x="106.09"
          y="30.709999"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={2.0699999}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect16"
        />
        <motion.rect
          x="109.46"
          y="30.709999"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={2.21}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect18"
        />
        <motion.rect
          x="113.24"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={1.58}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect20"
        />
        <motion.rect
          x="116.18"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={1.85}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect22"
        />
        <motion.rect
          x="119.54"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={2.8800001}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect24"
        />
        <motion.rect
          x="123.47"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={3.6400001}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect26"
        />
        <motion.rect
          x="128.21001"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={1.5}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect28"
        />
        <motion.rect
          x="131.00999"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={8.2700005}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect30"
        />
        <motion.rect
          x="140.63"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={2.1400001}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect32"
        />
        <motion.rect
          x="143.92"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={3.5699999}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect34"
        />
        <motion.rect
          x="148.91"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={2.5}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect36"
        />
        <motion.rect
          x="152.39999"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={3.4200001}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect38"
        />
        <motion.rect
          x="156.61"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={9.3400002}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect40"
        />
        <motion.rect
          x="167.57001"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={5.9899998}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect42"
        />
        <motion.rect
          x="175.8"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={2.9200001}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect44"
        />
        <motion.rect
          x="30.469999"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={2.4200001}
          initial="hidden"
          animate="visible"
          height="50.830002"
          id="rect46"
        />
        <motion.rect
          x="33.900002"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={2.8199999}
          initial="hidden"
          animate="visible"
          height="50.830002"
          id="rect48"
        />
        <motion.rect
          x="38.43"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={1.71}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect50"
        />
        <motion.rect
          x="41.57"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={1.5700001}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect52"
        />
        <motion.rect
          x="44.549999"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={1.5700001}
          initial="hidden"
          animate="visible"
          height="29.040001"
          id="rect54"
        />
        <motion.rect
          x="47.560001"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={3.8499999}
          height="29.040001"
          id="rect56"
        />
        <motion.rect
          x="52.990002"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={2.4200001}
          height="29.040001"
          id="rect58"
        />
        <motion.rect
          x="56.700001"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={7.27}
          height="29.040001"
          id="rect60"
        />
        <motion.rect
          x="65.199997"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={2.9200001}
          height="29.040001"
          id="rect62"
        />
        <motion.rect
          x="69.620003"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={2.71}
          height="29.040001"
          id="rect64"
        />
        <motion.rect
          x="180.46001"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={3.5699999}
          height="29.040001"
          id="rect66"
        />
        <motion.rect
          x="185.64999"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={3.71}
          height="29.040001"
          id="rect68"
        />
        <motion.rect
          x="190.5"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={2.4300001}
          height="29.040001"
          id="rect70"
        />
        <motion.rect
          x="194.5"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={2.0699999}
          height="29.040001"
          id="rect72"
        />
        <motion.rect
          x="197.39999"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={1.78}
          height="29.040001"
          id="rect74"
        />
        <motion.rect
          x="200.39999"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={3.49}
          height="29.040001"
          id="rect76"
        />
        <motion.rect
          x="205.61"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={5.2800002}
          height="29.040001"
          id="rect78"
        />
        <motion.rect
          x="212.47"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={0.86000001}
          height="29.040001"
          id="rect80"
        />
        <motion.rect
          x="214.67999"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={1.35}
          height="29.040001"
          id="rect82"
        />
        <motion.rect
          x="217.24001"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={1}
          height="29.040001"
          id="rect84"
        />
        <motion.rect
          x="219.99001"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={4.21}
          height="29.040001"
          id="rect86"
        />
        <motion.rect
          x="225.71001"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={3}
          height="29.040001"
          id="rect88"
        />
        <motion.rect
          x="230.27"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={1.9299999}
          height="29.040001"
          id="rect90"
        />
        <motion.rect
          x="234.06"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={3.28}
          height="29.040001"
          id="rect92"
        />
        <motion.rect
          x="239.12"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={2.28}
          height="50.82"
          id="rect94"
        />
        <motion.rect
          x="242.34"
          y="30.709999"
          class="st0"
          transition={{ width: { duration: 2, ease: "easeInOut", delay: 1 } }}
          variants={draw}
          custom={3.3599999}
          height="50.82"
          id="rect96"
        />
        <motion.g>
          <motion.g id="g124">
            {/* <motion.path
              d="M 51.07,81.62 H 47.39 V 73.13 C 47.39,72.11 47.34,70.37 44,70.37 42.37,70.37 41.11,71.47 41.11,73.6 V 81.61 H 38.43 V 67.64 h 3.52 v 2.05 H 42 c 0.5,-0.84 1.55,-2.42 4.2,-2.42 2.73,0 4.86,1.6 4.86,4.68 v 9.67 z"
              id="path98"
              fill="none"
              stroke="blue" // Change the color as needed
              strokeWidth="2" // Change the stroke width as needed
              initial="hidden"
              animate="visible"
              transition={{
                pathLength: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] }
              }}
              variants={pathVariants}
            /> */}
            <motion.path
              // class="st1"
              transition={{
                pathLength: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1], delay: 2 }
              }}
              variants={text}
              stroke="white"
              strokeWidth="0.2"
              initial="hidden"
              animate="visible"
              d="M 51.07,81.62 H 47.39 V 73.13 C 47.39,72.11 47.34,70.37 44,70.37 42.37,70.37 41.11,71.47 41.11,73.6 V 81.61 H 38.43 V 67.64 h 3.52 v 2.05 H 42 c 0.5,-0.84 1.55,-2.42 4.2,-2.42 2.73,0 4.86,1.6 4.86,4.68 v 9.67 z"
              id="path98"
            />
            <motion.path
              transition={{
                pathLength: { duration: 2, ease: "easeInOut", delay: 0.15 },
                fill: { duration: 2, ease: [1, 0, 0.8, 1], delay: 2 }
              }}
              variants={text}
              stroke="white"
              strokeWidth="0.2"
              initial="hidden"
              animate="visible"
              d="m 81.79,81.62 h -3.68 v -8.49 c 0,-2.08 -0.92,-2.76 -2,-2.76 -1.84,0 -2.5,1.21 -2.5,2.86 v 8.38 H 69.93 V 73.2 c 0,-1.18 0,-2.84 -2.21,-2.84 -1.73,0 -2.29,1.21 -2.29,2.73 V 81.6 H 61.75 V 67.64 h 3.52 v 2.05 h 0.05 c 0.47,-0.92 1.5,-2.42 3.97,-2.42 2.02,0 2.94,0.74 3.89,2.23 0.47,-0.68 1.5,-2.23 3.73,-2.23 2.73,0 4.86,1.6 4.86,4.68 v 9.67 z"
              id="path100"
            />
            <motion.path
              transition={{
                pathLength: { duration: 2, ease: "easeInOut", delay: 0.3 },
                fill: { duration: 2, ease: [1, 0, 0.8, 1], delay: 2 }
              }}
              variants={text}
              stroke="white"
              strokeWidth="0.2"
              initial="hidden"
              animate="visible"
              d="m 96.48,78.89 c 0,1.66 0.37,2.02 0.81,2.18 v 0.55 h -3.97 c -0.21,-0.66 -0.26,-0.87 -0.34,-1.66 -0.84,0.87 -2.02,2.02 -4.52,2.02 -2.1,0 -4.26,-1.18 -4.26,-4.07 0,-2.73 1.73,-4.12 4.07,-4.47 l 3.31,-0.5 c 0.58,-0.08 1.29,-0.32 1.29,-1.1 0,-1.55 -1.47,-1.63 -2.42,-1.63 -1.84,0 -2.15,1.13 -2.26,1.94 h -3.55 c 0.42,-4.28 3.39,-4.89 6.25,-4.89 1.97,0 5.57,0.6 5.57,4.05 v 7.58 z m -3.6,-4.08 c -0.37,0.29 -0.97,0.53 -2.5,0.76 -1.37,0.24 -2.5,0.58 -2.5,2.1 0,1.29 1,1.68 1.71,1.68 1.63,0 3.28,-1.05 3.28,-2.76 v -1.78 z"
              id="path102"
            />
            <motion.path
              transition={{
                pathLength: { duration: 2, ease: "easeInOut", delay: 0.45 },
                fill: { duration: 2, ease: [1, 0, 0.8, 1], delay: 2 }
              }}
              variants={text}
              stroke="white"
              strokeWidth="0.2"
              initial="hidden"
              animate="visible"
              d="m 99.74,67.64 h 3.52 v 2.42 h 0.05 c 0.76,-1.42 1.6,-2.79 3.84,-2.79 0.24,0 0.47,0.03 0.71,0.05 v 3.73 C 107.54,71 107.15,71 106.81,71 c -2.86,0 -3.39,1.79 -3.39,3.34 v 7.28 h -3.68 z"
              id="path104"
            />
            <motion.path
              transition={{
                pathLength: { duration: 2, ease: "easeInOut", delay: 0.6 },
                fill: { duration: 2, ease: [1, 0, 0.8, 1], delay: 2 }
              }}
              variants={text}
              stroke="white"
              strokeWidth="0.2"
              initial="hidden"
              animate="visible"
              d="m 113.38,65.99 h -3.68 v -3.42 h 3.68 z m 0,1.65 V 81.62 H 109.7 V 67.64 Z"
              id="path106"
            />
            <motion.path
              transition={{
                pathLength: { duration: 2, ease: "easeInOut", delay: 0.75 },
                fill: { duration: 2, ease: [1, 0, 0.8, 1], delay: 2 }
              }}
              variants={text}
              stroke="white"
              strokeWidth="0.2"
              initial="hidden"
              animate="visible"
              d="m 129.54,81.62 h -3.68 v -8.49 c 0,-1.02 -0.05,-2.76 -2.39,-2.76 -1.63,0 -2.89,1.1 -2.89,3.23 v 8.01 H 116.9 V 67.64 h 3.52 v 2.05 h 0.05 c 0.5,-0.84 1.55,-2.42 4.2,-2.42 2.73,0 4.86,1.6 4.86,4.68 v 9.67 z"
              id="path108"
            />
            <motion.path
              transition={{
                pathLength: { duration: 2, ease: "easeInOut", delay: 0.9 },
                fill: { duration: 2, ease: [1, 0, 0.8, 1], delay: 2 }
              }}
              variants={text}
              stroke="white"
              strokeWidth="0.2"
              initial="hidden"
              animate="visible"
              d="m 145.6,81.62 h -3.68 v -8.49 c 0,-1.02 -0.05,-2.76 -2.39,-2.76 -1.63,0 -2.89,1.1 -2.89,3.23 v 8.01 h -3.68 V 62.75 h 3.68 v 6.7 h 0.05 c 0.47,-1.16 2.05,-2.18 4.05,-2.18 2.73,0 4.86,1.6 4.86,4.68 z"
              id="path110"
            />
            <motion.path
              transition={{
                pathLength: { duration: 2, ease: "easeInOut", delay: 1.05 },
                fill: { duration: 2, ease: [1, 0, 0.8, 1], delay: 2 }
              }}
              variants={text}
              stroke="white"
              strokeWidth="0.2"
              initial="hidden"
              animate="visible"
              d="m 155.35,81.99 c -3.76,0 -7.15,-2.31 -7.15,-7.36 0,-5.04 3.39,-7.36 7.15,-7.36 3.76,0 7.15,2.31 7.15,7.36 0,5.05 -3.39,7.36 -7.15,7.36 z m 0,-11.62 c -2.84,0 -3.36,2.47 -3.36,4.26 0,1.79 0.53,4.26 3.36,4.26 2.84,0 3.36,-2.47 3.36,-4.26 0,-1.79 -0.52,-4.26 -3.36,-4.26 z"
              id="path112"
            />
            <motion.path
              transition={{
                pathLength: { duration: 2, ease: "easeInOut", delay: 1.2 },
                fill: { duration: 2, ease: [1, 0, 0.8, 1], delay: 2 }
              }}
              variants={text}
              stroke="white"
              strokeWidth="0.2"
              initial="hidden"
              animate="visible"
              d="m 173.93,81.62 h -1.58 V 68.01 h 1.58 v 2.05 h 0.05 c 0.81,-1.55 2.44,-2.42 4.2,-2.42 2.52,0 3.42,1.18 3.99,2.63 0.89,-1.76 2.5,-2.63 4.44,-2.63 3.71,0 4.23,2.65 4.23,5.39 v 8.59 h -1.58 v -9.41 c 0,-1.94 -0.68,-3.15 -2.92,-3.15 -1.16,0 -2.29,0.5 -3.07,1.55 -0.68,0.89 -0.89,2.55 -0.89,3.68 v 7.33 h -1.58 v -9.41 c 0,-1.94 -0.68,-3.15 -2.92,-3.15 -1.16,0 -2.29,0.5 -3.07,1.55 -0.68,0.89 -0.89,2.55 -0.89,3.68 v 7.33 z"
              id="path114"
            />
            <motion.path
              transition={{
                pathLength: { duration: 2, ease: "easeInOut", delay: 1.35 },
                fill: { duration: 2, ease: [1, 0, 0.8, 1], delay: 2 }
              }}
              variants={text}
              stroke="white"
              strokeWidth="0.2"
              initial="hidden"
              animate="visible"
              d="m 193.56,74.81 c 0,-3.97 2.1,-7.17 6.31,-7.17 4.26,0 6.31,3.18 6.31,7.17 0,4.31 -2.5,7.17 -6.31,7.17 -3.47,-0.04 -6.31,-2.41 -6.31,-7.17 z m 6.31,-5.75 c -3.31,0 -4.62,2.81 -4.62,5.75 0,3.39 1.6,5.75 4.62,5.75 3.31,0 4.62,-2.84 4.62,-5.75 -0.02,-3.2 -1.28,-5.75 -4.62,-5.75 z"
              id="path116"
            />
            <motion.path
              transition={{
                pathLength: { duration: 2, ease: "easeInOut", delay: 1.5 },
                fill: { duration: 2, ease: [1, 0, 0.8, 1], delay: 2 }
              }}
              variants={text}
              stroke="white"
              strokeWidth="0.2"
              initial="hidden"
              animate="visible"
              d="m 219.58,62.7 h 1.58 v 18.92 h -1.58 v -2.39 h -0.05 c -0.92,1.79 -3,2.76 -4.73,2.76 -4.39,0 -6.15,-3.13 -6.15,-7.15 0,-3.81 1.92,-7.2 6.07,-7.2 1.92,0 3.86,0.89 4.86,2.55 z m -4.6,6.36 c -3.26,0 -4.78,2.76 -4.65,6.17 0.03,3.42 2,5.33 4.65,5.33 2.97,0 4.6,-2.52 4.6,-5.81 0,-3.06 -1.73,-5.69 -4.6,-5.69 z"
              id="path118"
            />
            <motion.path
              transition={{
                pathLength: { duration: 2, ease: "easeInOut", delay: 1.65 },
                fill: { duration: 2, ease: [1, 0, 0.8, 1], delay: 2 }
              }}
              variants={text}
              stroke="white"
              strokeWidth="0.2"
              initial="hidden"
              animate="visible"
              d="m 231.38,73.68 c 0.58,-0.05 1.55,-0.21 2.08,-0.45 0.53,-0.24 0.58,-0.95 0.58,-1.42 0,-1.6 -0.95,-2.76 -3.39,-2.76 -2.18,0 -3.68,0.71 -3.97,3.07 h -1.6 c 0.24,-3.21 2.63,-4.49 5.6,-4.49 2.76,0 4.94,1.08 4.94,4.23 v 7.41 c 0,1.24 0.32,1.52 1.66,1.05 v 1.23 c -0.24,0.08 -0.79,0.26 -1.24,0.26 -0.26,0 -0.47,-0.03 -0.71,-0.08 -1.05,-0.16 -1.24,-1.08 -1.26,-2.02 -1.31,1.47 -3.1,2.26 -5.07,2.26 -2.39,0 -4.54,-1.29 -4.54,-3.92 0,-2.29 1.58,-3.68 4.76,-4.1 z m 2.66,0.79 c -1.34,0.55 -2.71,0.66 -4.07,0.76 -2.39,0.18 -3.84,1.05 -3.84,2.89 0,1.66 1.45,2.44 2.94,2.44 2.5,0 4.97,-1.21 4.97,-3.97 z"
              id="path120"
            />
            <motion.rect
              transition={{
                pathLength: { duration: 2, ease: "easeInOut", delay: 1.8 },
                fill: { duration: 2, ease: [1, 0, 0.8, 1], delay: 2 }
              }}
              variants={text}
              stroke="white"
              strokeWidth="0.2"
              initial="hidden"
              animate="visible"
              x="54.470001"
              y="77.709999"
              class="st0"
              width="4"
              height="3.9100001"
              id="rect122"
            />
          </motion.g>
        </motion.g>
      </motion.g>
    </motion.svg>
  );
};
