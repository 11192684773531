import React from 'react'

const Fakini = require("../img/1 -fakini.png")
const Hering = require("../img/2 - hering.png")
const LogHaus = require("../img/3 - loghaus.png")
const Malwee = require("../img/4 - malwee-logo-0.png")
const Texpa = require("../img/5 - texpa.png")
const LaModa = require("../img/6 - la-moda.png")
const Gatomia = require("../img/7---gatomia.png")
const LezALez = require("../img/8 - lez-a-lez.png")
const GrupoKrindges = require("../img/9 - grupo-krindges.png")
const GrupoParaiso = require("../img/10 - grupo-paraiso.png")
const BanaBana = require("../img/11 - bana-bana.png")
const Pituchinhus = require("../img/12 - pituchinhus.png")
const GrupoCistina = require("../img/13 - grupo cristina.png")
const Abrange = require("../img/14 - abrange.png")
const Colisao = require("../img/15 - colisÆo.png")
const LaMandinne = require("../img/16 - la-mandinne.png")
const SlyWear = require("../img/17 - sly-wear.png")
const Svk = require("../img/18 - svk.png")
const Ima = require("../img/ima.png")
const Central = require("../img/central.png")

export const Companies = () => {
    return (
        <div className='companies'>
            <h3 class="ntitle">
                APENAS ALGUNS DE NOSSOS CLIENTES
            </h3>
            <span class="ntext nblack">
                De novas empresas a marcas consolidadas no mercado,
                <br/>
                nossas pesquisas e treinamentos já atingiram +400 empresas de moda no Brasil
            </span>
            <div class="logos">
                <img src={Ima.default} alt="Fakini" />
                <img src={Central.default} alt="Fakini" />
                <img src={Fakini.default} alt="Fakini" />
                <img src={Hering.default} alt="logo" />
                <img src={LogHaus.default} alt="logo" />
                <img src={Malwee.default} alt="logo" />
                <img src={Texpa.default} alt="logo" />
                <img src={LaModa.default} alt="logo" />
                <img src={Gatomia.default} alt="logo" />
                <img src={LezALez.default} alt="logo" />
                <img src={GrupoKrindges.default} alt="logo" />
                <img src={GrupoParaiso.default} alt="logo" />
                <img src={BanaBana.default} alt="logo" />
                <img src={Pituchinhus.default}  alt="logo" />
                <div class="i13">
                    <img src={GrupoCistina.default} alt="logo" />
                </div>
                <div>
                    <img src={Abrange.default} alt="logo" />
                </div>
                <div>
                    <img src={Colisao.default} alt="logo" />
                </div>
                <img src={LaMandinne.default} alt="logo" />
                <img src={SlyWear.default} alt="logo" />
                <div>
                    <img src={Svk.default} alt="logo" />
                </div>

            </div>
        </div>


    )
}
