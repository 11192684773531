import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import anime from "animejs";

const TYPE_AFTER_MS = 800;
const JUMP_AFTER_MS = 35;

export default function FullWidthImage(props) {
  const {
    height = 400,
    img,
    title,
    subheading,
    imgPosition = "top left",
  } = props;
    const [ref, setRef] = useState(null)

  useEffect(() => {
      if (ref) {
        const blink = anime({
          targets: '.text-animation .cursor',
          loop: true,
          duration: 750,
          opacity: [
            {value: [1, 1]},
            {value: [0, 0]}
          ],
        })
          // ref.innerHTML = ref.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
          const letters = Array.from(document.querySelectorAll(".letters"))
          anime.timeline({ loop: false })
          .add({
            targets: '.ml16 .cursor',
            translateX: letters.map((letter, i) =>
              ({value: letter.offsetLeft + letter.offsetWidth, duration: 1, delay: i === 0 ? 0 : JUMP_AFTER_MS}))
          }, TYPE_AFTER_MS)
          .add({
            targets: '.ml16 .letter',
            opacity: [0, 0.40, 0.80, 1],
            duration: 10,
            delay: anime.stagger(JUMP_AFTER_MS),
            changeBegin: () => {
              blink.reset();
              blink.pause();
            },
            // changeComplete: () => {
            //   blink.restart();
            // }
          }, TYPE_AFTER_MS)
          // .add({
          //   targets: '.text-animation',
          //   opacity: 0,
          //   duration: 1000,
          //   delay: 500,
          //   easing: 'easeOutExpo',
          // });
              // .add({
              //   opacity: [0, 1],
              //   duration: 1,
              //   delay: anime.stagger(JUMP_AFTER_MS),
              //   changeBegin: () => {
              //     blink.reset();
              //     blink.pause();
              //   },
              //   changeComplete: () => {
              //     blink.restart();
              //   }

              //   // targets: '.ml16 .letter',
              //   // translateY: [-100,0],
              //   // easing: "easeOutExpo",
              //   // duration: 1400,
              //   // opacity: 1,
              //   // delay: (el, i) => 30 * i
              // });
      }

  }, [ref])
  
    const spans = title.split(" ").map(word => (
                    <span className="word">
                        {word.split("").map(s => <span className="letter">{s}</span>)}
                        &nbsp;
                    </span>
                ))

  return (
    <React.Fragment>
      <div
        className="margin-top-0"
        style={{
          display: "grid",
          position: "relative",
          alignItems: "center",
          height: "100vh",
          backgroundImage: `url(${img.url || img.images.fallback.src})`,
          filter: "grayscale(0.4)",
          backgroundSize: "cover"
        }}
      >
        {(title || subheading) && (
          <div
            style={{
                height: "100%"
            }}
          >
            {/* Any content here will be centered in the component */}
            {title && (
              <h1
                ref={setRef}
                className="main-title ntitle ml16"
                style={{
                  fontWeight: 700,
                }}
              >
                {spans}
              </h1>
            )}
            
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  subheading: PropTypes.string,
};
