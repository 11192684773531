import React from "react"
import { motion } from "framer-motion";
import {AnimatedLogo1} from "./AnimatedLogo1"

const draw = {
  hidden: { pathLength: 0, opacity: 1, fill: "rgba(255, 255, 255, 0)" },
  visible: (i) => {
    const delay = 1 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      fill: "rgb(255, 255, 255, 1)",

      transition: {
        pathLength: { delay: 0, type: "spring", duration: 3000 },
        fill: { delay: 2, duration: 1 },
        // fill: { duration: 1, delay: 5 },
      },
    };
  },
};

const AnimatedInstagramLogo = () => {
    return (
                        <motion.svg
      initial="hidden"
      animate="visible"
      showgrid="false"
        class="MuiSvgIcon-root jss180" focusable="false" viewBox="0 0 24 24" aria-hidden="true" title="fontSize medium">
         <motion.path
        stroke="white"
        fill="white"
        transition={{ ease: [0.17, 0.67, 0.83, 0.67] }}
        strokeWidth={0.3}
        d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z"
        variants={draw}
        custom={1}
      />

        </motion.svg>
    )
}

const AnimatedPhone = () => {
    return (
                        <motion.svg
      initial="hidden"
      animate="visible"
      showgrid="false"
        class="MuiSvgIcon-root jss180" focusable="false" viewBox="0 0 24 24" aria-hidden="true" title="fontSize medium">
         <motion.path
        stroke="white"
        fill="white"
        transition={{ ease: [0.17, 0.67, 0.83, 0.67] }}
        strokeWidth={0.3}
        d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"
        variants={draw}
        custom={1}
      />

        </motion.svg>
    )
}

const AnimatedWhatsapp = () => {
    return (
                        <motion.svg
      initial="hidden"
      animate="visible"
      showgrid="false"
        class="MuiSvgIcon-root jss180" focusable="false" viewBox="0 0 24 24" aria-hidden="true" title="fontSize medium">
         <motion.path
        stroke="white"
        fill="white"
        transition={{ ease: [0.17, 0.67, 0.83, 0.67] }}
        strokeWidth={0.3}
        d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"
        variants={draw}
      />

        </motion.svg>
    )
}
export const FixedBar = () => {
    return (
        <>
            <div className="fixed-bar">
                <div className="nlogo">
                  <AnimatedLogo1 />
                </div>
                <div className="contacts ntext">
                    <a target="_blank" href="https://www.instagram.com/nmarinhomoda/" rel="noopener noreferrer">
                        <AnimatedInstagramLogo />
                    </a>
                    <a className="phone" rel="noopener noreferrer" href="call:+554733252708">
                        <AnimatedPhone/>
                        (47) 3325-2708
                    </a>
                    <a className="" href="https://api.whatsapp.com/send?phone=554792667379" target="_blank">
                        <button className="nbutton ntext">
                    <AnimatedWhatsapp />
                    Contato
                        </button>
                    </a>
                </div>
            </div>
            <a className="mobile-button" href="https://api.whatsapp.com/send?phone=554792667379" target="_blank">
                <button className="nbutton">
                    <AnimatedWhatsapp />
                Contato
                </button>
            </a>
        </>
    )
}
