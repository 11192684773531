import React, { memo, useMemo } from 'react'

export const Description = memo(({ text, ...rest }) => {
    const html = useMemo(() => {
        var bold = /\*\*(.*?)\*\*/gm;
        var html = (text || "").replace(bold, '<strong>$1</strong>');

        html = html.replace(/(?:\r\n|\r|\n)/g, '<br>');
        let elements = html.match(/\[.*?\)/g);
        if( elements != null && elements.length > 0){
          for(const el of elements){
            let txt = el.match(/\[(.*?)\]/)?.[1]
            let url = el.match(/\((.*?)\)/)?.[1]
            if (txt && url) {
                html = html.replace(el,'<a href="'+url+'" target="_blank" rel="noopener noreferrer">'+txt+'</a>')
            }
          }
        }
        return html
    }, [text])

    return (
        <span className='ntext' {...rest} dangerouslySetInnerHTML={{ __html: html }} />
    )
})
