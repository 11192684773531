import {getImage} from 'gatsby-plugin-image'
import React from 'react'

export const Footer = ({ instagram }) => {
    return (
        <div className="nsection nfooter">
            <h4 className='ntext'> Pronto para moldar um </h4>
            <h2 className='ntitle'> futuro criativo? </h2>


            <a href="https://api.whatsapp.com/send?phone=554792667379" target="_blank">
                <button className='nbutton ntitle main-button'>
                    Converse conosco!
                </button>
            </a>
            <div className='social-links'>
                <a href="https://www.instagram.com/nmarinhomoda/" target="_blank">
                    <svg class="MuiSvgIcon-root jss180" focusable="false" viewBox="0 0 24 24" aria-hidden="true" title="fontSize medium"><path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z"></path></svg>
                </a>
                <a className="phone" href="call:+554733252708">
                    <svg class="MuiSvgIcon-root jss180" focusable="false" viewBox="0 0 24 24" aria-hidden="true" title="fontSize medium"><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"></path></svg>
                </a>
                <a href="https://api.whatsapp.com/send?phone=554792667379" className="" target="_blank">
                    <svg class="MuiSvgIcon-root jss180" focusable="false" viewBox="0 0 24 24" aria-hidden="true" title="fontSize medium"><path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path></svg>
                </a>
                <a href="mailto:contato@nmarinho.com.br">
                    <svg class="MuiSvgIcon-root jss180" focusable="false" viewBox="0 0 24 24" aria-hidden="true" title="fontSize medium"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path></svg>
                </a>
            </div>

            <span className='ntitle keepintouch'> KEEP IN TOUCH </span>
            <span className='ntitle'> Siga-nos no Instagram </span>
            <div className="igcards">
                {instagram.cards.map((c, i) => (
                    <a target="_blank" rel="noopener noreferrer" className='igcard' href={`https://instagram.com/p/${c.id}`}>
                        <img key={i} src={getImage(c.image)?.images.fallback.src || c.image} />
                    </a>
                ))}
            </div>
        </div>
    )
}
