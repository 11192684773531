import React from 'react'
import { Card } from './Card'
import { graphql, useStaticQuery } from "gatsby";
import {getImage} from 'gatsby-plugin-image';

const query = graphql`
  query Section {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        section_background {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }

`
export const Section = ({ section }) => {
    const data = useStaticQuery(query)
    const { frontmatter } = data.markdownRemark;
    const image = getImage(frontmatter.section_background) || frontmatter.section_background
    return (
        <div className="nsection" style={{ backgroundImage: `url(${image.url || image.images.fallback.src})` }}>
            <div className="title-stripe ntitle">
                {section.title}
            </div>
            <div className="cards">
                {section.cards.map((c, i) => (
                    <Card key={i} card={c} />
                ))}
            </div>
        </div>
    )
}
