import React from 'react'
import { motion } from 'framer-motion'
import {Description} from './Description'


export const Card = ({ card }) => {
    return (
        <motion.div
            className="ncard"  initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, transition: { duration: 3 } }}
            viewport={{ once: true }}
            whileHover={{ scale: [null, 1.05, 1.02] }}
        >
            <div className="ncard-title ntext">
                {card.title}
            </div>
            <div className="ncard-text ntext">
                <Description text={card.description}/>
            </div>
            <div className="ncard-button ntext">
                <a href={card.button_link} target="_blank" rel="noopener noreferrer">
                    <button className='ntext'> {card.button_title} </button>
                </a>
            </div>
        </motion.div>
    )
}
